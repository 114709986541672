import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import {photolabAddTask, photolabWaitTask} from "../../../photolab/api";
import ImageView from "../../components/ImageView";
import StyleProcessingStep from "./StyleProcessingStep";

const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSING = 1;
const STATUS_PROCESSED = 2;

export default class LayoutProcessingStep extends React.Component {

  state = {
    tick: 0,
    isProcessing: true,
    layouts: [],
    selectedLayout: {},
  };

  componentDidMount() {
    const styleConfig = this.context.templatesConfig.styles.find((config) => config.id === this.props.processings.results[StyleProcessingStep.STEP_ID].id);

    const layouts = styleConfig.layouts.map((config) => {
      return {
        id: config.id,
        thumbnailFileUrl: config.thumbnail.url,
        status: STATUS_PENDING,
        combo: this.context.templatesConfig.combos.find((combo) => combo.id === config.result_combo_id),
        comboStep: 0,
        result: {
          steps: [],
          resultUrl: undefined,
        }
      };
    });

    this.setState({
      layouts,
      selectedLayout: layouts[0],
      isProcessing: false,
    });

    this.process(layouts[0]);
  }

  process = (layout) => {
    layout.status = STATUS_PROCESSING;

    const stepConfig = layout.combo.steps[layout.comboStep];
    const fileUrl = layout.comboStep === 0
        ? this.props.processings.results[StyleProcessingStep.STEP_ID].result.resultUrl
        : layout.result.steps[layout.comboStep - 1].resultUrl;

    const taskConfig = new PhotolabTaskBuilder()
        .addMethod(new PhotolabTaskCollageMethod(stepConfig.id))
        .addImage(new PhotolabTaskImageUrl(fileUrl))
        .build();

    photolabAddTask(taskConfig)
        .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 1000, 1000))
        .then((taskResult) => this.handleComboStepResult(layout, taskResult))
        .catch((error) => this.handleComboStepFailure(layout, error));
  };

  handleComboStepResult = (layout, taskResult) => {
    layout.result.steps.push(taskResult);

    if (layout.comboStep + 1 < layout.combo.steps.length) {
      layout.comboStep++;
      this.process(layout);
    } else {
      layout.result.resultUrl = taskResult.resultUrl;
      layout.status = STATUS_PROCESSED;
    }

    this.setState({tick: Date.now()});
  };

  handleComboStepFailure = (layout, error) => {
    layout.status = STATUS_FAILED;
  };

  handleLayoutSelect = (layout) => {
    if (layout.status === STATUS_PENDING) {
      this.process(layout);
    }

    this.setState({
      selectedLayout: layout,
    });
  };

  handleLayoutResultClick = (layout) => {
    this.props.onStepComplete(layout);
  };

  render() {
    if (this.state.isProcessing) {
      return <div>Processing...</div>;
    }

    return <section className="layout-page">
      <div className="container">
        <LayoutView layout={this.state.selectedLayout} onConfirmClick={this.handleLayoutResultClick} />
      </div>

      <div className="thumbnail-view-container">
        {this.state.layouts.map((layout) => <ThumbnailView
            key={layout.id}
            layout={layout}
            isActive={this.state.selectedLayout.id === layout.id}
            onClick={this.handleLayoutSelect}
        />)}
      </div>
    </section>;
;
  }
}

LayoutProcessingStep.contextType = AppContext;
LayoutProcessingStep.propTypes = {
  onStepComplete: PropTypes.func.isRequired,
};
LayoutProcessingStep.STEP_ID = "layout";

function ThumbnailView({layout, isActive, onClick}) {
  return <button
    className={"btn-choice-template" + (isActive ? " active" : "")}
    style={{backgroundImage: `url(${layout.thumbnailFileUrl})`}}
    onClick={() => onClick(layout)}
  />;
}

function LayoutView({layout, onConfirmClick}) {
  const isProcessed = layout.status === STATUS_PROCESSED;

  return <div className="image-view-container">
    <Loader hidden={isProcessed} />
    {isProcessed && <ImageView image={{url: layout.result.resultUrl}} square />}
    <button
      className="btn-done"
      hidden={!isProcessed}
      onClick={() => onConfirmClick(layout)}>
        <svg viewBox="0 0 64 48" id="icon-done">
          <g fill-rule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <path d="M20.356 37.867L5.156 22.933 0 28 20.356 48 64 5.067 58.844 0z" transform="translate(-1042 -1776) translate(48 240) translate(930 1464) translate(64 72)"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
    </button>
  </div>
}

function Loader({hidden}) {
  if (window.clientConfig.isWebviewIOS) {
    return <IosLoader hidden={hidden} />
  } else {
    return <AndroidLoader hidden={hidden} />
  }
}

function IosLoader({hidden}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container">
    <div className="loader-ios">
      <span className="item-loader first-item-loader" />
      <span className="item-loader second-item-loader" />
      <span className="item-loader third-item-loader" />
    </div>
    <p dangerouslySetInnerHTML={{__html: "Processing..."}} />
  </div>;
}

function AndroidLoader({hidden}) {
  if (hidden) {
    return <React.Fragment />;
  }

  return <div className="loader-container android">
    <div>
      <p dangerouslySetInnerHTML={{__html: "Processing..."}} />
      <div className="loader-android"><span /></div>
    </div>
  </div>;
}