import * as amplitude from "amplitude-js";
import api from "./api";

let webviewParamsIsCommited = false;

const userProperties = {
  client_type: window.clientConfig.isWebview ? "webview" : (window.clientConfig.isWebMobile ? "mobile" : "desktop"),
  is_webview: window.clientConfig.isWebview,
  is_mobile: window.clientConfig.isWebMobile,
  platform_browser_name: window.clientConfig.platform.name,
  platform_browser_version: window.clientConfig.platform.version,
  platform_os: window.clientConfig.platform.os,
  screen_w: window.screen.width,
  screen_h: window.screen.height,
  viewport_w: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
  viewport_h: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
  locale: window.clientConfig.locale,
  is_pro: window.clientConfig.isPro,
  split_group: window.clientConfig.splitGroupId,
};

if (window.clientConfig.isWebview) {
  const osName = window.clientConfig.isWebviewAndroid
    ? "Android"
    : "iOS";

  userProperties.os_version = `${osName} ${window.clientConfig.webviewParams.os_version}`;
  userProperties.native_app_build = `${osName} ${window.clientConfig.webviewParams.version_code}`;
}

const userPropertiesCommitWaited = Object.keys(userProperties);

if (window.appConfig.isDebug) {
  console.log("initial user properties", userProperties);
}

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({...userProperties});
}

export const userEvents = {
  PAGE_INDEX: "page_index",
  PAGE_RESULT: "page_result",
  PHOTO_SELECT: "photo_select",
  SHARE: "share",
  DOWNLOAD: "download",
};

export function logEvent(eventId, eventParams, cb) {
  eventParams = eventParams || {};

  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }

  if (window.appConfig.analytics.isEnabled) {
    let userParams = undefined;
    if (userPropertiesCommitWaited.length > 0) {
      userParams = {};
      userPropertiesCommitWaited.forEach((key) => userParams[key] = userProperties[key]);
      userPropertiesCommitWaited.length = 0;
    }

    let webviewParams = undefined;
    if (window.clientConfig.isWebview && !webviewParamsIsCommited) {
      webviewParamsIsCommited = true;
      webviewParams = window.clientConfig.webviewParams;
    }

    api.logEvent(eventId, eventParams, userParams, webviewParams)
      .catch(console.error);
  }
}

export function setUserProperty(key, value) {
  if (window.appConfig.isDebug) {
    console.log("setUserProperty", key, value);
  }

  userProperties[key] = value;
  userPropertiesCommitWaited.push(key);

  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().identify(new amplitude.Identify().set(key, value));
  }
}