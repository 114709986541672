import React from "react";
import AppContext from "../../../contexts/AppContext";
import routes from "../../../routes";
import StyleProcessingStep from "./StyleProcessingStep";
import LayoutProcessingStep from "./LayoutProcessingStep";

export default class ProcessingPage extends React.Component {

  state = {
    sourceFileUrl: "",
    processings: {
      results: {
        [StyleProcessingStep.STEP_ID]: {},
        [LayoutProcessingStep.STEP_ID]: {},
      },
    },
    currentStep: StyleProcessingStep.STEP_ID,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.fileUrl) {
      const nextState = {
        sourceFileUrl: this.props.location.state.fileUrl,
      };

      this.setState(nextState, this.start);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  handleStyleStepComplete = (style) => {
    const processings = this.state.processings;

    processings.results[StyleProcessingStep.STEP_ID] = style;

    this.setState({
      currentStep: LayoutProcessingStep.STEP_ID,
      processings
    });
  };

  handleLayoutStepComplete = (layout) => {
    const processings = this.state.processings;

    processings.results[LayoutProcessingStep.STEP_ID] = layout;

    this.setState({
      processings
    });

    this.props.history.replace({
      pathname: routes.RESULT,
      state: {fileUrl: layout.result.resultUrl}
    });
  };

  render() {
    if (this.state.currentStep === StyleProcessingStep.STEP_ID) {
      return <StyleProcessingStep
        sourceFileUrl={this.state.sourceFileUrl}
        onStepComplete={this.handleStyleStepComplete}
      />;
    }

    if (this.state.currentStep === LayoutProcessingStep.STEP_ID) {
      return <LayoutProcessingStep
        processings={this.state.processings}
        onStepComplete={this.handleLayoutStepComplete}
      />;
    }

    return <div>Wrong state</div>;
  }
}

ProcessingPage.contextType = AppContext;