import React from "react";
import AppContext from "../../../contexts/AppContext";
import {logEvent, userEvents} from "../../../utils/log";
import ResultPageView from "./ResultPageView";
import routes from "../../../routes";
import {webviewShare} from "../../../utils/webview";

export default class ResultPage extends React.Component {

  state = {
    resultFileUrl: "",
  };

  componentDidMount() {
    logEvent(userEvents.PAGE_RESULT);

    if (this.props.location.state && this.props.location.state.fileUrl) {
      const nextState = {
        resultFileUrl: this.props.location.state.fileUrl,
      };

      this.setState(nextState);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  handleShareClick = () => {
    webviewShare({
      providers: "[9]",
      imageUrl: encodeURIComponent(this.state.resultFileUrl),
    });
  }

  render() {
    const props = {
      ...this.state,
      onShareClick: this.handleShareClick,
    };

    return <ResultPageView {...props} />;
  }
}

ResultPage.contextType = AppContext;