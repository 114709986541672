import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../../contexts/AppContext";
import PhotolabTaskBuilder from "../../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../../photolab/PhotolabTaskImageUrl";
import {photolabAddTask, photolabWaitTask} from "../../../photolab/api";
import ImageView from "../../components/ImageView";

export default class StyleProcessingStep extends React.Component {

  state = {
    tick: 0,
    isProcessing: true,
    styles: [],
  };

  componentDidMount() {
    const styles = this.context.templatesConfig.styles.map((styleConfig) => {
      const selectedVariant = styleConfig.variants.find((v) => v.is_default === 1);
      return {
        id: styleConfig.id,
        name: styleConfig.title.en,
        status: 0,
        combo: this.context.templatesConfig.combos.find((combo) => combo.id === selectedVariant.result_combo_id),
        comboStep: 0,
        result: {
          steps: [],
          resultUrl: undefined,
        }
      };
    });

    this.setState({styles}, this.start);
  }

  start = () => {
    this.state.styles.forEach((style, index) => {
      setTimeout(() => this.process(style), 100 * index);
    });
  };

  process = (style) => {
    const stepConfig = style.combo.steps[style.comboStep];
    const fileUrl = style.comboStep === 0
      ? this.props.sourceFileUrl
      : style.result.steps[style.comboStep - 1].resultUrl;

    const taskConfig = new PhotolabTaskBuilder()
      .addMethod(new PhotolabTaskCollageMethod(stepConfig.id))
      .addImage(new PhotolabTaskImageUrl(fileUrl))
      .build();

    photolabAddTask(taskConfig)
      .then((addTaskResult) => photolabWaitTask(addTaskResult.requestId, 1000, 1000))
      .then((taskResult) => this.handleComboStepResult(style, taskResult))
      .catch((error) => this.handleComboStepFailure(style, error));
  };

  handleComboStepResult = (style, taskResult) => {
    style.result.steps.push(taskResult);

    if (style.comboStep + 1 < style.combo.steps.length) {
      style.comboStep++;
    } else {
      style.result.resultUrl = taskResult.resultUrl;
      style.status = 1;
    }

    this.setState({tick: Date.now()}, this.handleProcessingStatus);
  };

  handleComboStepFailure = (style, error) => {

  };

  handleProcessingStatus = () => {
    this.setState({
      isProcessing: this.state.styles.count((style) => style.status === 1) < 4,
    });
  };

  handleStyleResultClick = (style) => {
    this.props.onStepComplete(style);
  };

  render() {
    if (this.state.isProcessing) {
      return <div>Processing...</div>;
    }

    return <section className="proccesing-step-page">
      <div className="container">
        {this.state.styles.map((style) => <StyleItemView
          key={style.id}
          style={style}
          onClick={this.handleStyleResultClick}
        />)}
      </div>
    </section> ;
  }
}

StyleProcessingStep.contextType = AppContext;
StyleProcessingStep.propTypes = {
  sourceFileUrl: PropTypes.string.isRequired,
  onStepComplete: PropTypes.func.isRequired,
};
StyleProcessingStep.STEP_ID = "style";

function StyleItemView({style, onClick}) {
  const isProcessed = style.status === 1;

  return <div className="item-view" onClick={() => onClick(style)}>
    <p>{style.name}</p>
    {isProcessed && <ImageView image={{url: style.result.resultUrl}} square />}
  </div>;
}