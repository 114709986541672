import React from "react";
import AppContext from "../../../contexts/AppContext";
import {logEvent, userEvents} from "../../../utils/log";
import IndexPageView from "./IndexPageView";
import routes from "../../../routes";

export default class IndexPage extends React.Component {

  componentDidMount() {
    logEvent(userEvents.PAGE_INDEX);
  }

  handleFileSelected = (file) => {
    //if (window.clientConfig.isWebview) {
      // file is array
    //} else {
      this.props.history.push({
        pathname: routes.PROCESSING,
        state: {fileUrl: file}
      });
    //}
  };

  render() {
    const props = {
      onFileSelected: this.handleFileSelected,
      onFileUrlSelected: this.handleFileSelected,
    };

    return <IndexPageView {...props} />;
  }
}

IndexPage.contextType = AppContext;