import React from "react";
import {Link} from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import ImageView from "../../components/ImageView";
import routes from "../../../routes";

export default class ResultPageView extends React.Component {
  render() {
    return <section className="result-page">
      <div className="container">
        <ImageView image={{url: this.props.resultFileUrl}} square />

        <div className="btns-container">
          <Link to={routes.INDEX}>try another photo</Link>
          <button onClick={() => this.props.onShareClick()}>share</button>
        </div>
      </div>
    </section>;
  }
}

ResultPageView.contextType = AppContext;
