import React from "react";
import PropTypes from "prop-types";
import AppContext from "../../../contexts/AppContext";
import FileChooseButton from "../../components/FileChooseButton";

export default class IndexPageView extends React.Component {

  state = {
    urlFieldValue: "https://photolab-tester2.test3.robothumb.com/storage/1c/e3/8d/108.jpeg",
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.onFileUrlSelected(this.state.urlFieldValue);
  };

  render() {
    return <main>
      <FileChooseButton
        children={"Select your photo"}
        onFileSelected={this.props.onFileSelected} />
      <hr />

      <form onSubmit={this.handleFormSubmit}>
        <input
          type="text"
          value={this.state.urlFieldValue}
          onChange={(e) => this.setState({urlFieldValue: e.target.value})} />
        <input
          type="submit" />
      </form>

    </main>;
  }
}

IndexPageView.contextType = AppContext;
IndexPageView.propTypes = {
  onFileSelected: PropTypes.func.isRequired,
  onFileUrlSelected: PropTypes.func.isRequired,
};