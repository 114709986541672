import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import ModalWrapper from "./ui/modals/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import IndexPage from "./ui/pages/index/IndexPage";
import * as webviewUtils from "./utils/webview";
import * as Sentry from "@sentry/react";
import "./ui/styles/app.scss";
import ProcessingPage from "./ui/pages/processing/ProcessingPage";
import ResultPage from "./ui/pages/result/ResultPage";

if (window.appConfig.sentry.isEnabled) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: window.appConfig.sentry.dsn,
  });
  Sentry.setUser({token: window.clientConfig.token});
}

class App extends React.Component {

  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.loadTemplatesConfig();

    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-lang--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    if (window.clientConfig.isWebviewAndroid) {
      document.body.classList.add("app-webview--android");
    } else if (window.clientConfig.isWebviewIOS) {
      document.body.classList.add("app-webview--ios");
    }

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => this.context.setInstalledApps(apps));
    }
  }

  loadTemplatesConfig = () => {
    window.axios.get("/templates-config.json")
      .then((res) => res.data)
      .then((res) => {
        this.context.setTemplatesConfig(res);
        this.setState({isLoading: false});
      })
      .catch((err) => console.error(err))
  };

  render() {
    if (this.state.isLoading) {
      return <div>Loading...</div>;
    }

    return <React.Fragment>
      <Switch>
        <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />
        <Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} />} />
        <Route exact path={routes.RESULT} render={props => <ResultPage {...props} />} />
      </Switch>
      <ModalWrapper />
    </React.Fragment>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);