export const apiResponseErrorCodes = {
  INTERNAL: 1,
  INVALID_PARAMS: 2,
  PHOTOLAB: 3,
  NOT_AUTHORIZE: 401,
  NOT_FOUND: 404,
  DELETED: 410,
  FILE_FORMAT_INVALID: 415,
};

export class ApiResponseError extends Error {

  constructor(data) {
    super();

    this.name = "ApiResponseError";
    this.code = data.error_code;
    this.message = `Code: ${data.error_code}, Message: ${data.error_message}`;
    this.response = data;
  }
}

function checkApiResponse(res) {
  if (res.data.error_code) {
    throw new ApiResponseError(res.data);
  } else {
    return res.data;
  }
}

function paramsToStr(params = {}, random = true) {
  if (random) {
    params.r = Math.random();
  }

  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
}

function logEvent(eventId, eventParams, userParams, webviewParams) {
  return window.axios.post(window.appConfig.analytics.endpoint, {
    id: eventId,
    params: eventParams,
    user_params: userParams,
    webview_params: webviewParams,
  }).then(checkApiResponse);
}

export function photolabSign(data) {
  return window.axios.post(window.appConfig.paths.apiSign + "/photolab/sign", {data})
    .then(checkApiResponse);
}

export default {
  logEvent,
  photolabSign,
};