import React from "react";

const AppContext = React.createContext({
  installedApps: {},
  modals: [],
  templatesConfig: null,
});

const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      installedApps: {},
      modals: [],
      templatesConfig: null,
    };
  }

  render() {
    const state = {
      ...this.state,
      setInstalledApps: (apps) => {
        this.setState({installedApps: apps});
      },
      pushModal: (component, cb) => {
        const modals = this.state.modals.slice();
        modals.push(component);

        this.setState({modals}, () => cb && cb());
      },
      popModal: (cb) => {
        const modals = this.state.modals.slice();
        modals.pop();

        this.setState({modals}, () => cb && cb());
      },
      setTemplatesConfig: (config) => {
        this.setState({templatesConfig: config});
      },
    };

    return <AppContext.Provider value={state} children={this.props.children}/>;
  }
}

export default AppContext;

export {AppContextConsumer, AppContextProvider};
